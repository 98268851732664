/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "rnd",
            "endpoint": "https://f2xp82kk13.execute-api.us-east-1.amazonaws.com/v1",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:1efdd93b-879c-4c17-af0b-0f81b7833522",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_TwbE0OA1I",
    "aws_user_pools_web_client_id": "29rf3o6bf2i2ap6nb78mras183",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 16,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "rnd-prod-hostingbucket.reassigned.us",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2qjb7gxzffyaj.cloudfront.net"
};


export default awsmobile;
